.creme-container {
  background-color: $creme-light;
  position: relative;
  z-index: 1;
}

.white-container {
  background-color: $white;
  position: relative;
  z-index: 1;
}

.zigzag-top, .zigzag-bottom {
  position: absolute;
  width: 100%;
  &::before {
    content: '';
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    filter: drop-shadow(0px 0px 9px #000);
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%; top:0;
    height: 100%;
  }
}

.zigzag-top {
  top: 0;
  .creme-container & {
    &::before {
      background-image: url("/assets/img/creme_element_top.png");
      top: -30px;
      height: 63px;
    }
    &::after {
      background-color: $creme-light;
    }
  }
  .white-container & {
    &::before {
      background-image: url("/assets/img/white_element_top.png");
      top: -30px;
      height: 63px;
    }
    &::after {
      background-color: $white;
    }
  }
}

.zigzag-bottom {
  bottom: 0;
  .creme-container & {
    &::before {
      background-image: url("/assets/img/creme_element_bottom.png");
      background-position-y: bottom;
      bottom: -30px;
      height: 63px;
    }
    &::after {
      background-color: $creme-light;
    }
  }
  .white-container & {
    &::before {
      background-image: url("/assets/img/white_element_bottom.png");
      bottom: -65px;
      height: 141px;
    }
    &::after {
      background-color: $white;
    }
  }
}
