.form {
    position: relative;
    padding-left: 50px;
    @include respond-to(small) {
        padding-left: 0;
        &:before {
            display: none;
        }
    }
    &:before {
        content: '';
        background-color: $orange;
        width: 3px;
        height: 160px;
        position: absolute;
        top: 0;
        left: 0;
    }
    .form-title {
        color: $orange;
        font-size: 50px;
        font-weight: 400;
        line-height: 50px;
        letter-spacing: 8px;
        text-transform: uppercase;
        margin: 0 0 25px;
    }
    .form-field {
        margin-bottom: 25px;
    }
    label {
        display: block;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 5px;
        @include respond-to(small) {
            font-size: 16px;
            line-height: 20px;
        }
    }
    input, textarea {
        background-color: $white;
        border: 2px solid $white;
        padding: 10px 30px;
        width: 100%;
        height: 54px;
        line-height: 54px;
        -webkit-box-shadow: none;
        box-shadow: none;
        outline: none;
        @include respond-to(small) {
            height: 42px;
            line-height: 42px;
            padding: 10px 20px;
        }
    }
    input[type=file] {
        line-height: 30px;
        @include respond-to(small) {
            line-height: 1;
        }
    }
    input[type=submit] {
        -webkit-appearance: none;
        border: none !important;
        height: auto;
        width: auto;
        @include respond-to(small) {
            width: 100%;
        }
    }
    textarea {
        resize: none;
        height: auto;
        line-height: normal;
    }

}

.form-field {
    input {
        border: 2px solid $white;
    }
    P {
        display: none;
    }
    &.error {
        input {
            border: 2px red solid;
        }
        p {
            display: block;
            color: red;
            margin: 0;
            font-size: 14px;
        }
    }
}

textarea:focus, input:focus, textarea:active, input:active{
    border: 2px solid $dark-purple;
    -webkit-box-shadow: none;
    box-shadow: none;
}

::-webkit-input-placeholder { /* Edge */
    color: $dark-purple;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $dark-purple;
}

::placeholder {
    color: $dark-purple;
}