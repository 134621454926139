.carousel-container {
    position: relative;
    padding-top: 170px;
    margin-bottom: 150px;
    z-index: 2;
    @include respond-to(medium-large) {
        padding-top: 0;
        margin-bottom: 120px;
    }
    &:last-child {
        margin-bottom: 0;
    }
    &.left {
        .carousel-square {
            left: 145px;
            @include respond-to(extra-large) {
                left: 65px;
            }
            @include respond-to(medium-large) {
                left: 0;
            }
        }
    }
    &.right {
        .carousel-square {
            right: 145px;
            @include respond-to(extra-large) {
                right: 65px;
            }
            @include respond-to(medium-large) {
                right: 0;
            }
        }
    }
}

.carousel-item {
    height: auto;
    padding-bottom: calc(675/1720*100%);
    img {
        display: block;
        position: absolute;
        width: 100%;        
        height: 100%;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        &.loaded {
            opacity: 1;
        }
    }
}

.swiper-lazy-preloader {
    border-color: transparent $dark-orange $dark-orange;
}


// Carousel square

.carousel-square {
    background-color: $creme-dark;
    width: 416px;
    height: 343px;
    text-align: center;
    padding: 20px;
    position: absolute;
    top: 10px;
    z-index: 2;
    @include respond-to(extra-large) {
        width: 316px;
        height: 243px;
    }
    @include respond-to(medium-large) {
        position: relative;
        top: 0;
        width: 100%;
        padding-top: 80px;
        height: auto;
        z-index: 1;
    }
    &:after {
        content: '';
        background: url('/assets/img/square_bottom.png') center no-repeat;
        position: absolute;
        left: 0;
        bottom: -55px;
        width: 100%;
        height: 59px;
        @include respond-to(medium-large) {
            background-size: cover;
        }
    }
    h2 {
        font-size: 50px;
        font-weight: 800;
        line-height: 50px;
        text-transform: uppercase;
        @include respond-to(extra-large) {
            font-size: 36px;
            line-height: 36px;
        }
        @include respond-to(medium-large) {
            font-size: 40px;
            line-height: 40px;
        }
    }
}

.mobile-carousel-description {
    display: none;
    line-height: 1.3;
    @include respond-to(medium-large) {
        display: block;
    }
}

.carousel-description {
    position: absolute;
    top: 10px;
    z-index: 3;
    max-width: 640px;
    @include respond-to(medium-large) {
        display: none;
        top: auto;
        bottom: 0px;
        right: auto !important;
        left: 50% !important;
        transform: translateX(-50%);
        text-align: center !important;
    }
    &:before {
        content: '';
        background-color: $orange;
        width: 3px;
        height: 100%;
        position: absolute;
        top: 0;
        @include respond-to(medium-large) {
            display: none;
        }
    }
    p {
        margin: 0;
        line-height: 1.3;
        @include respond-to(extra-large) {
            font-size: 20px;
            //line-height: 22px;
        }
        @include respond-to(medium-large) {
            font-size: 16px;
            padding: 0 30px;
        }
    }
}

.pagingInfo {
    font-size: 22px;
    line-height: 1.4;
    position: absolute;
    top: 280px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    @include respond-to(extra-large) {
        top: 210px;
    }
    @include respond-to(medium-large) {
        top: 40px;
    }
}

.slick-slider {
    position: relative;
}

// Carousel pagination
.swiper {
    .swiper-pagination {
        position: static;
        padding: 16px 0;
    }
    
    $inner-bulet-size: 15px;
    .swiper-pagination-bullet {
        width: $inner-bulet-size;
        height: $inner-bulet-size;
        margin: 6px !important;
        transition: all 0.15s;
        &:hover {
            opacity: 0.5;
        }
    }
    
    .swiper-pagination-bullet-active {
        background: $dark-orange;
    }
}


// Carousel controls
.slick-arrow {
    background-color: transparent;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border: none;
    text-indent: -9999px;
    cursor: pointer;
    z-index: 3;
    position: absolute;
    top: 110px;
    width: 50px;
    height: 50px;
    @include respond-to(extra-large) {
        top: 40px;
    }
    @include respond-to(medium-large) {
        top: auto;
        bottom: -80px;
    }
}
.slick-arrow:hover {
    opacity: 0.7;
}
.slick-arrow:after {
    content: '';
    background-color: transparent;
    border: solid $orange;
    border-width: 0 3px 3px 0;
    padding: 3px;
    overflow: hidden;
    display: inline-block;
    width: 55px;
    height: 55px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    @include respond-to(medium-large) {
        width: 40px;
        height: 40px;
    }
}
.carousel-container.left {
    .carousel-description { 
        left: 660px;
        @include respond-to(extra-large) {
            left: 480px;
        }
        &:before {
            left: -30px;
        }
    }
    .slick-prev {
        left: 220px;
        @include respond-to(extra-large) {
            left: 110px;
        }
        @include respond-to(medium-large) {
            left: 80px !important;
            right: auto !important;
        }
    }
    .slick-next {
        left: 440px;
        @include respond-to(extra-large) {
            left: 290px;
        }
        @include respond-to(medium-large) {
            left: auto !important;
            right: 80px !important;
        }
    }
}
.carousel-container.right {
    .carousel-description { 
        right: 660px;
        text-align: right;
        @include respond-to(extra-large) {
            right: 480px;
        }
        &:before {
            right: -30px;
        }
    }
    .slick-prev {
        right: 440px;
        @include respond-to(extra-large) {
            right: 280px;
        }
        @include respond-to(medium-large) {
            left: 80px !important;
            right: auto !important;
        }
    }
    .slick-next {
        right: 220px;
        @include respond-to(extra-large) {
            right: 115px;
        }
        @include respond-to(medium-large) {
            left: auto !important;
            right: 80px !important;
        }
    }
}
.slick-prev:after {
    transform: translateY(-50%) rotate(135deg);
    -webkit-transform: translateY(-50%) rotate(135deg);
    left: 35%;
}
.slick-next:after {
    transform: translateY(-50%) rotate(-45deg);
    -webkit-transform: translateY(-50%) rotate(-45deg);
    right: 35%;
}