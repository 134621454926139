// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.grecaptcha-badge {
  width: 70px !important;
  visibility: hidden;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;
}
.grecaptcha-badge:hover {
  width: 256px !important;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  color: $dark-purple;
  font-family: "Barlow", sans-serif;
}
body.fixed {
  overflow: hidden;
}

.wrapper {
  max-width: 1660px;
  margin: 0 auto;
  padding: 0 30px;
  position: relative;
  overflow: hidden;

  &.smaller {
    max-width: 1390px;
  }
  @include respond-to(small) {
    padding: 0 10px;
  }
}

h1 {
  font-size: 90px;
  font-weight: 800;
  line-height: 90px;

  @include respond-to(extra-large) {
    font-size: 70px;
    line-height: 70px;
  }
  @include respond-to(small) {
    font-size: 40px;
    line-height: 40px;
  }
}

p {
  font-family: "Nunito", sans-serif;
  font-weight: 300;
  font-size: 22px;
  line-height: 33px;

  @include respond-to(small) {
    font-size: 20px;
    line-height: 24px;
  }
}

/**
 * Basic styles for links
 */
a {
  text-decoration: none;
  outline: none;
  &:active {
    outline: none;
  }

  @include on-event {
    text-decoration: none;
    outline: none;
  }
}

a[href^="tel"] {
  color: inherit;
  text-decoration: none;
  outline: none;
}

ul {
  margin: 0;
  padding: 0;
}

.section-title {
  font-size: 100px;
  font-weight: 800;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: -2px;

  @include respond-to(extra-large) {
    font-size: 70px;
  }
}

/*
 * Make all images responsive
 */
img {
  max-width: 100%;
  max-height: 100%;
}

/* Grid */
.flex-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -40px;

  &.two-column {
    .flex-item {
      width: 50%;
      @include respond-to(medium-large) {
        width: 100%;
      }
    }
  }
  &.three-column {
    .flex-item {
      width: 33.3%;
      @include respond-to(medium-large) {
        width: 100%;
      }
    }
  }
  &.four-column {
    .flex-item {
      width: 25%;
      @include respond-to(medium-large) {
        width: 100%;
      }
    }
  }
  .flex-item {
    padding-left: 40px;
    @include respond-to(medium-large) {
      width: 100%;
    }
  }
}
