@import "../../styles/utils/variables", "../../styles/utils/mixins";

.game-ready {
  cursor: pointer;

  :hover {
    filter: brightness(1.2);
  }
}

.game-nr {
  cursor: default; // not-allowed;
  opacity: 0.6;
}

.section {
  text-align: center;
  padding: 90px 0;

  @include respond-to(medium) {
    padding: 60px 0;
  }
}

.container {
  display: flex;
  max-width: 2400px;

  @include respond-to(large) {
    max-width: 800px;
  }
  @include respond-to(medium) {
    max-width: 400px;
    margin: 0 auto;
    display: block;
  }
}

$text-container-width: 360px;

.text-container {
  min-width: $text-container-width;
  padding: 0 20px;

  h2 {
    margin: 0 0 30px;
  }
  p {
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    margin: 10px 0 0;
  }
  .btn {
    margin-top: 40px;
  }

  @include respond-to(small) {
    min-width: 0;
    padding: 0 10px;
    h2 {
      font-size: 50px;
      margin-bottom: 16px;
    }
    .btn {
      margin-top: 25px;
    }
  }
}

.carousel {
  flex: 1;
  align-self: center;
  min-width: calc(100% - $text-container-width);
  padding: 0 20px;

  @include respond-to(medium) {
    min-width: 0;
    margin-top: 50px;
  }
  @include respond-to(small) {
    padding: 0 10px;
  }
}
