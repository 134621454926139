@import "../../styles/utils/variables", "../../styles/utils/mixins";

.modal-overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.75);
  animation: fadeIn 0.3s ease-in-out forwards;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  
  @include respond-to(small) {
    background: none;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
  }
}

.modal {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  max-width: 1200px;
  height: 90vh;
  max-height: -webkit-fill-available;
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.3);
  animation: slideIn 0.3s ease-in-out forwards;
  overflow: hidden;
  
  @include respond-to(small) {
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    min-height: 100vh;
    min-height: 100dvh;
    max-height: -webkit-fill-available;
    background: none;
    border-radius: 0;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
    transform: none;
    box-shadow: none;

    :global(.burger-icon),
    :global(.header-nav) {
      display: none !important;
    }
  }
}

.mobile-exit {
  display: none;
  
  @include respond-to(small) {
    display: block;
    position: fixed;
    top: env(safe-area-inset-top, 16px);
    left: env(safe-area-inset-left, 16px);
    z-index: 10001;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    color: white;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: background-color 0.2s;

    &::before {
      content: '←';
      display: block;
    }

    &:active {
      background: rgba(0, 0, 0, 0.7);
    }
  }
}

.modal-content {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  
  @include respond-to(small) {
    padding: 0;
  }
  
  h2 {
    margin: 0 0 15px;
    font-size: 24px;
    color: $dark-purple;
    padding-right: 40px;
    
    @include respond-to(small) {
      display: none;
    }
  }
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 40px;
  height: 40px;
  border: none;
  background: linear-gradient(145deg, $orange, darken($orange, 15%));
  border-radius: 50%;
  font-size: 28px;
  line-height: 1;
  color: $white;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;
  box-shadow: 0 4px 15px rgba($orange, 0.3),
              inset 0 -2px 5px rgba(0, 0, 0, 0.2);
  transform: rotate(0deg);
  z-index: 10001;
  
  @include respond-to(small) {
    display: none;
  }
  
  &::before {
    content: '×';
    display: block;
    transform: translateY(-1px);
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }
  
  @media (hover: hover) {
    &:hover {
      transform: rotate(90deg) scale(1.1);
      box-shadow: 0 6px 20px rgba($orange, 0.4),
                  inset 0 -2px 5px rgba(0, 0, 0, 0.2);
    }
  }
  
  &:active {
    transform: rotate(90deg) scale(0.95);
    box-shadow: 0 2px 10px rgba($orange, 0.3),
                inset 0 -1px 3px rgba(0, 0, 0, 0.2);
  }
}

.iframe-container {
  flex: 1;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  background: #f5f5f5;
  
  @include respond-to(small) {
    border-radius: 0;
    height: 100vh;
    height: 100dvh;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    
    @include respond-to(small) {
      top: 0;
      height: 100%;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (min-width: 769px) {
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translate(-50%, -45%);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }
}

@media (max-width: 768px) {
  @keyframes slideIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
} 