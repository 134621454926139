.sub-navigation {
    padding: 80px 0 50px;
    @include respond-to(small) {
        padding: 60px 0 40px;
    }

    li {
        border-left: 3px solid $orange;
        display: inline-block;
        list-style: none;
        padding: 6px 30px;
        margin-bottom: 30px;
        text-align: center;
        &:last-child {
            border-right: 3px solid $orange;
        }
        @include respond-to(small) {
            border-left: none;
            display: block;
            margin: 0 auto 20px;
            position: relative;
            &:last-child {
                border-right: none;
                &:after {
                    content: '';
                    background-color: $orange;
                    display: block;
                    width: 50px;
                    height: 3px;
                    position: absolute;
                    bottom: -10px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
            &:before {
                content: '';
                background-color: $orange;
                display: block;
                width: 50px;
                height: 3px;
                position: absolute;
                top: -10px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        a {
            color: $black;
            font-family: 'Nunito', sans-serif;
            font-size: 22px;
            font-weight: 700;
            line-height: 33px;
            &:hover {
                color: $orange;
            }
        }
    }
}