.privacy-policy {
  padding: 4rem 1rem;
  background-color: #fff1d9;
  min-height: 100vh;
}

.privacy-container {
  max-width: 800px;
  margin: 0 auto;
  background: white;
  padding: 2.5rem;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.privacy-policy h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1rem;
  text-align: center;
}

.last-updated {
  color: #666;
  text-align: center;
  margin-bottom: 2rem;
  font-style: italic;
}

.privacy-intro {
  margin-bottom: 3rem;
  color: #555;
  line-height: 1.6;
}

.privacy-policy section {
  margin-bottom: 3rem;
}

.privacy-policy .subsection {
  margin-bottom: 2rem;
}

.privacy-policy h2 {
  color: #2c3e50;
  font-size: 1.75rem;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #e9ecef;
}

.privacy-policy h3 {
  color: #34495e;
  font-size: 1.4rem;
  margin: 1.5rem 0 1rem;
}

.privacy-policy h4 {
  color: #34495e;
  font-size: 1.2rem;
  margin: 1.25rem 0 1rem;
}

.privacy-policy p {
  color: #555;
  line-height: 1.6;
  margin-bottom: 1rem;
}

.privacy-policy ul {
  list-style-type: none;
  padding-left: 1.5rem;
  margin-bottom: 1.5rem;
}

.privacy-policy ul li {
  color: #555;
  margin-bottom: 1rem;
  position: relative;
}

.privacy-policy ul li::before {
  content: "•";
  color: #007bff;
  font-weight: bold;
  position: absolute;
  left: -1.5rem;
}

.privacy-policy .definitions-list li,
.privacy-policy .cookie-types li,
.privacy-policy .cookie-purposes li,
.privacy-policy .use-cases li,
.privacy-policy .sharing-situations li {
  margin-bottom: 1.5rem;
}

.privacy-policy .definitions-list p,
.privacy-policy .cookie-types p,
.privacy-policy .cookie-purposes p,
.privacy-policy .use-cases p,
.privacy-policy .sharing-situations p {
  margin-bottom: 0.5rem;
}

.privacy-policy a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.2s ease;
}

.privacy-policy a:hover {
  color: #0056b3;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .privacy-policy {
    padding: 2rem 1rem;
  }
  
  .privacy-container {
    padding: 1.5rem;
  }
  
  .privacy-policy h1 {
    font-size: 2rem;
  }
  
  .privacy-policy h2 {
    font-size: 1.5rem;
  }
  
  .privacy-policy h3 {
    font-size: 1.25rem;
  }
  
  .privacy-policy h4 {
    font-size: 1.1rem;
  }
} 