// Burger icon
.burger-icon {
  display: none;
  @include respond-to(medium-large) {
    display: block;
    position: absolute;
    top: 70px;
    right: 30px;
    width: 37px;
    height: 25px;
    z-index: 10;
  }
  @include respond-to(small) {
    top: 60px;
    right: 10px;
  }
}
.burger-icon span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: $white;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.burger-icon span:nth-child(1) {
  top: 0px;
}

.burger-icon span:nth-child(2),
.burger-icon span:nth-child(3) {
  top: 10px;
}

.burger-icon span:nth-child(4) {
  top: 20px;
}

.burger-icon.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.burger-icon.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.burger-icon.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.burger-icon.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.btn {
  display: inline-block;
  color: $white;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
  padding: 15px 30px;

  &.disabled {
    background-color: $light-grey;
    cursor: not-allowed;
  }

  &.dark-orange {
    background-color: $dark-orange;
    &:hover {
      background-color: $orange;
    }
  }

  &.dark-purple {
    background-color: $dark-purple;
    &:hover {
      background-color: $orange;
    }
  }
}

a.social-link {
  display: inline-block;
  width: 50px;
  height: 50px;
  text-indent: -9999px;
  background-repeat: no-repeat;

  &.facebook {
    background-image: url("/assets/icons/facebook.png");
    &:hover {
      background-image: url("/assets/icons/facebook_hover.png");
    }
  }

  &.twitter {
    background-image: url("/assets/icons/twitter.png");
    &:hover {
      background-image: url("/assets/icons/twitter_hover.png");
    }
  }

  &.linkedin {
    background-image: url("/assets/icons/linkedin.png");
    &:hover {
      background-image: url("/assets/icons/linkedin_hover.png");
    }
  }

  &.instagram {
    background-image: url("/assets/icons/instagram.png");
    &:hover {
      background-image: url("/assets/icons/instagram_hover.png");
    }
  }

  &.pinterest {
    background-image: url("/assets/icons/pinterest.png");
    &:hover {
      background-image: url("/assets/icons/pinterest_hover.png");
    }
  }
}
