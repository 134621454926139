@import "../../styles/utils/variables", "../../styles/utils/mixins";

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.about-us-section {
  position: relative;
  padding: 4rem 0;
  overflow: hidden;
  min-height: auto;
  display: block;
  background: #fff1d9;

  .wrapper {
    position: relative;
    z-index: 1;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
    width: 100%;
  }

  .title {
    animation: fadeInUp 0.8s ease-out forwards;
    margin-bottom: 1.5rem;
    text-align: center;
    font-size: 2.25rem;
    color: #4a3c32;
    
    @media (max-width: 768px) {
      font-size: 1.75rem;
      margin-bottom: 1rem;
    }
  }

  .content {
    display: flex;
    gap: 4rem;
    align-items: center;
    margin: 1.5rem auto 3rem;
    background: rgba(255, 255, 255, 0.95);
    border-radius: 20px;
    padding: 2.5rem;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    
    @media (max-width: 1024px) {
      gap: 3rem;
      padding: 2rem;
      margin-bottom: 2.5rem;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 2rem;
      padding: 1.5rem;
      margin-bottom: 2rem;
    }
  }

  .section-text {
    flex: 1;
    animation: fadeInRight 0.8s ease-out forwards;
    animation-delay: 0.2s;
    opacity: 0;
    
    .paragraph {
      font-size: 1.125rem;
      line-height: 1.8;
      margin-bottom: 2rem;
      color: #4a5568;
      
      &:last-child {
        margin-bottom: 0;
      }

      @media (max-width: 768px) {
        font-size: 1rem;
        line-height: 1.7;
        margin-bottom: 1.5rem;
      }
    }
  }

  .section-image {
    flex: 1;
    display: flex;
    justify-content: center;
    animation: fadeInLeft 0.8s ease-out forwards;
    animation-delay: 0.4s;
    opacity: 0;
    filter: drop-shadow(0 10px 20px rgba(0, 0, 0, 0.1));
    transition: transform 0.3s ease;
    
    &:hover {
      transform: translateY(-5px);
    }
    
    img {
      max-width: 100%;
      height: auto;
      object-fit: contain;
      border-radius: 20px;
      
      @media (max-width: 768px) {
        max-width: 85%;
      }
    }
  }
}