@import "../../styles/utils/variables", "../../styles/utils/mixins";

header {
  position: relative;
  /*
  background-repeat: no-repeat;
  background-position: 30% top;
  background-size: cover;
  */
  min-height: min(100vh,960px);
  @include respond-to(medium) {
    min-height: auto;
  }

  picture {
    img {
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 30% top;
    }
  }

  &.half-screen {
    height: 60vh;
    min-height: 500px;
    @include respond-to(medium-large) {
      height: auto;
      min-height: 360px;
    }
    .hero-content-container {
      position: absolute;
      bottom: 80px;
      margin: 0;
      padding-bottom: 0;
      @include respond-to(medium-large) {
        bottom: 70px;
      }
    }
  }
}

.header-container {
  max-width: 1390px;
  margin: 0 auto;
  padding: 0 30px;
  @include respond-to( small) {
    padding: 0 10px;
  }
}

.header-logo {
  float: left;
  margin-top: 40px;
  @include respond-to(extra-large) {
    max-width: 250px;
  }
  @include respond-to(small) {
    max-width: 170px;
  }
}

.header-nav {
  float: right;

  @include respond-to(medium-large) {
    display: none;
    float: none;
    &.show {
      display: block;
      position: fixed;
      background-color: rgba(0, 0, 0, 0.95);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 9;
      padding: 20px 30px;

      li {
        display: block;
        margin-right: 0;
        &:last-child {
          margin-left: 0;
        }

        a {
          &.active:before {
            top: 45px;
          }
          padding-left: 20px;
        }
      }
    }
  }

  li {
    position: relative;
    display: inline-block;
    margin-right: 40px;
    padding-top: 50px;
    @include respond-to(extra-large) {
      margin-right: 30px;
    }
    @include respond-to(large) {
      margin-right: 10px;
    }

    a {
      &.active {
        &:before {
          content: "";
          background-color: $orange;
          position: absolute;
          top: 0;
          left: 0;
          width: 4px;
          height: 33px;
        }
      }

      color: $white;
      font-size: 20px;
      font-weight: 500;
      line-height: 20px;

      &:hover {
        color: $orange;
      }
    }
  }
}

.hero-content-container {
  color: $white;
  max-width: 868px;
  margin-top: 60px;
  padding-bottom: 190px;

  @include respond-to(medium) {
    //margin-top: 100px;
    padding-bottom: 70px;
  }
  @include respond-to(small) {
    margin-top: 40px;
  }

  h1 {
    margin: 0 0 -0.08em;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: -2px;
    @include respond-to(medium) {
      font-size: 48px;
    }
  }

  p {
    font-size: 30px;
    line-height: 1.1;
    font-weight: 400;
    max-width: 600px;
    margin: 1.8em 0;
    @include respond-to(extra-large) {
      font-size: 24px;
    }
    @include respond-to(small) {
      font-size: 20px;
      //margin-bottom: 30px;
    }
  }
  .btn {
    //margin-left: 100px;
    @include respond-to(medium) {
      margin-left: 0;
    }
    @include respond-to(small) {
      // : 100%;
      //display: block;
      //text-align: center;
      //letter-spacing: 2px;
    }
  }
}
