@import "../../styles/utils/variables", "../../styles/utils/mixins";

.our-team-section {
  text-align: center;
  padding: 90px 0;
 
  @include respond-to(medium) {
    padding: 60px 0;
  }

  .image {
    position: relative;

    &:before {
      content: "";
      background-color: $creme;
      width: 100%;
      height: 72%;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 1;
    }

    img {
      position: relative;
      display: block;
      margin: 0 auto;
      z-index: 2;
    }
  }

  h3 {
    font-size: 28px;
    font-weight: 600;
    line-height: 33px;
    margin: 0;

    @include respond-to(large) {
      font-size: 22px;
      line-height: 26px;
    }
  }

  h4,
  h5 {
    margin: 0;
  }

  p {
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    margin: 10px 0 0;
  }

}

.our-team-container {
  display: flex;
  align-items: center;
  
  @include respond-to(large) {
    max-width: 760px;
  }
  @include respond-to(medium) {
    display: block;
  }
}

$text-column-width: 380px;

.text-container {
  min-width: $text-column-width;
  padding: 0 20px;
  h2 {
    margin: 0 0 30px;
  }

  @include respond-to(medium) {
    margin: 0 auto 20px;
    padding: 0 10px;
    min-width: 0;
    max-width: 420px;
  }

  @media screen and (max-width: 568px) {
    max-width: 380px;
  }

  @include respond-to(small) {
    h2 {
      font-size: 50px;
      margin-bottom: 16px;
    }
  }
}

.our-team-carousel {
  flex: 1;
  align-self: center;
  min-width: calc(100% - $text-column-width);

  @media screen and (max-width: 568px) {
    max-width: 380px;
    margin: 0 auto;
  }
}

.slide {
  box-sizing: border-box;
  height: auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;

  @include respond-to(large) {
    padding: 0 10px;
  }
}

.team-member-description {
  background-color: $white;
  padding: 15px;
  flex: 1;
}