@import "../styles/utils/mixins", "../styles/utils/variables";

.contacts-container {
  padding: 80px 0;

  .flex-container {
    align-items: flex-start;
    .flex-item {
      &:first-child {
        width: 35%;
        @include respond-to(medium-large) {
          width: 100%;
        }
      }
      &:last-child {
        width: 65%;
        @include respond-to(medium-large) {
          width: 100%;
        }
      }
    }

    input[type="submit"] {
      width: 300px;
    }
  }

  li {
    list-style: none;
    margin-bottom: 40px;
    @include respond-to(medium-large) {
      margin-bottom: 20px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    span {
      display: inline-block;
      vertical-align: middle;
      margin-right: 25px;
    }
    p,
    a {
      color: $dark-purple;
      font-family: "Nunito", sans-serif;
      display: inline-block;
      vertical-align: middle;
      font-size: 20px;
      line-height: 26px;
      margin: 0;
      @include respond-to(medium-large) {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
  .form {
    @include respond-to(medium-large) {
      margin-top: 50px;
      padding-left: 0;
      &:before {
        display: none;
      }
    }
    &.thankyou {
      h2 {
        color: $green;
      }
      &:before {
        background-color: $green;
        height: 100%;
      }
    }
  }
  .reset-btn {
    cursor: pointer;
    display: inline-block;
    margin: 40px 0 0 55px;
    @include respond-to(medium-large) {
      margin: 40px 0 0 0;
    }
  }
}

.form {
  position: relative;
  padding-left: 50px;
  @include respond-to(small) {
    padding-left: 0;
    &:before {
      display: none;
    }
  }
  &:before {
    content: "";
    background-color: $orange;
    width: 3px;
    height: 160px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .form-title {
    color: $orange;
    font-size: 50px;
    font-weight: 400;
    line-height: 50px;
    letter-spacing: 8px;
    text-transform: uppercase;
    margin: 0 0 25px;
  }
  .form-field {
    margin-bottom: 25px;
  }
  label {
    display: block;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 5px;
    @include respond-to(small) {
      font-size: 16px;
      line-height: 20px;
    }
  }
  input,
  textarea {
    background-color: $white;
    border: 2px solid $white;
    padding: 10px 30px;
    width: 100%;
    height: 54px;
    line-height: 54px;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
    @include respond-to(small) {
      height: 42px;
      line-height: 42px;
      padding: 10px 20px;
    }
  }
  input[type="file"] {
    line-height: 30px;
    @include respond-to(small) {
      line-height: 1;
    }
  }
  input[type="submit"] {
    border: none !important;
    height: auto;
    @include respond-to(small) {
      width: 100%;
    }
  }
  textarea {
    resize: none;
    height: auto;
    line-height: normal;
  }
}

.form-field {
  input {
    border: 2px solid $white;
  }
  P {
    display: none;
  }
  &.error {
    input {
      border: 2px red solid;
    }
    p {
      display: block;
      color: red;
      margin: 0;
      font-size: 14px;
    }
  }
}

textarea:focus,
input:focus,
textarea:active,
input:active {
  border: 2px solid $dark-purple;
  -webkit-box-shadow: none;
  box-shadow: none;
}

::-webkit-input-placeholder {
  color: $dark-purple;
}

:-ms-input-placeholder {
  color: $dark-purple;
}

::placeholder {
  color: $dark-purple;
}

.btn.dark-purple {
  background-color: #2a000c;
}

.required-star {
  color: red;
  font-size: 1em;
  margin-left: 5px;
  position: relative;
  top: -5px;
  right: -5px;
}

.error-message {
  color: red;
  font-weight: bold;
}

.two-line-content {
  small {
    white-space: pre-wrap;
  }
}



