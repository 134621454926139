@import "../../styles/utils/variables", "../../styles/utils/mixins";

footer {
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: cover;
  padding: 230px 0 200px; // +30 because of zigzag above
  @include respond-to(medium) {
    padding: 130px 0 100px;
  }
}

.footer-left-side {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(19px);
  padding: 37px 40px;
  margin: 0 auto;
  max-width: 900px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @include respond-to(medium) {
    display: block;
  }

  @include respond-to(small) {
    padding: 30px 20px;
  }

  h2 {
    color: $white;
    font-size: 50px;
    font-weight: 800;
    line-height: 1;
    margin: 0;
    text-transform: uppercase;
    @include respond-to(extra-large) {
//      font-size: 30px;
    }
    @include respond-to(medium-large) {
      font-size: 40px;
      text-align: center;
    }
    @include respond-to(small) {
      font-size: 30px;
    }
  }
  a {

    @include respond-to(medium) {
      display: block;
      margin-top: 20px;
      text-align: center;
    }
    @include respond-to(small) {
      padding: 15px;
    }
  }
}

.footer-right-side {
  margin-right: 20px;
  text-align: center;

  .socials {
    margin: 100px -11px;

    li {
      display: inline-block;
      margin: 11px;
    }

    @include respond-to(extra-large) {
      margin-right: 1px;
          }
    @include respond-to(medium-large) {
      margin: 50px -8px;
      li {
        margin: 8px;
      }
    }
    @include respond-to(small) {
      margin-left: 10px;
    }
  }
}

.footer-info-links-container {
  color: white;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-info-center a,
.footer-info-right a {
  color: white;
  text-decoration: none;

  &:hover {
    color: $orange;
  }
}

.footer-info-center {
  color: white;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  gap: 20px 30px;
  justify-content: center;
  align-items: center;
}

.footer-info-right {
  font-size: 1.5rem;
  display: flex;
  justify-content: flex-end;
  padding-right: 150px;
}

@media (max-width: 767px) {
  .footer-info-center {
    flex-direction: column;
    align-items: center;
  }

  .footer-info-link-item {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
