// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
/// @see {mixin} respond-to
$breakpoints: (
  "extra-small": (
    max-width: 320px,
  ),
  "small": (
    max-width: 480px,
  ),
  "medium": (
    max-width: 768px,
  ),
  "medium-large": (
    max-width: 991px,
  ),
  "large": (
    max-width: 1024px,
  ),
  "extra-large": (
    max-width: 1450px,
  ),
  "ultra-large": (
    max-width: 1650px,
  ),
) !default;

// Colors
$black: #000000;
$white: #ffffff;
$dark-purple: #2a000c;
$yellow: #febb2f;
$orange: #e17e18;
$dark-orange: #ca4c02;
$light-grey: #616060;
$creme: #ffe8c4;
$creme-light: #fff1d9;
$creme-dark: #f0d7ae;
$green: #a7cc58;
