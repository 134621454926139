@import "../../styles/utils/variables", "../../styles/utils/mixins";

// Partners

.partners-section {
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: cover;
  padding: 130px 0;

  @include respond-to(medium-large) {
    padding: 80px 0;
  }
}


.partners {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  @include respond-to(small) {
    display: block;
    margin: 0 auto;
  }
}

.partner {
  text-align: center;
  padding: 20px 20px;
  flex: 0 1 20%;
  @include respond-to(extra-large) {
    flex: 0 1 25%;
  }
  @include respond-to(large) {
    flex: 0 1 33%;
  }
  @include respond-to(large) {
    flex: 0 1 50%;
  }
}
