.gdpr-consent {
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  width: 90%;
  max-width: 400px;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
  animation: slideUp 0.5s ease-out;
}

.gdpr-consent-content {
  padding: 1.5rem;
}

.gdpr-consent h3 {
  margin: 0 0 1rem 0;
  font-size: 1.2rem;
  color: #333;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.gdpr-consent p {
  margin: 0 0 1.5rem 0;
  font-size: 0.95rem;
  line-height: 1.5;
  color: #666;
}

.gdpr-consent-buttons {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.gdpr-accept-btn {
  padding: 0.75rem 1.5rem;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.gdpr-accept-btn:hover {
  background: #0056b3;
}

.gdpr-learn-more {
  color: #666;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.2s ease;
}

.gdpr-learn-more:hover {
  color: #333;
  text-decoration: underline;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translate(-50%, 20px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
} 