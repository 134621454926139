@import "../../styles/utils/variables.scss";
@import "../../styles/utils/mixins.scss";

.statistics-section {
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: cover;
  height: 550px;
  padding: 180px 0;
  @include respond-to(medium-large) {
    height: auto;
    padding: 50px 0 30px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
  }

  li {
    color: $white;
    list-style: none;
    text-align: center;
    width: 16.66%;
    &:last-child {
      .number:after {
        content: "";
        position: absolute;
        top: 20px;
        right: 0;
        width: 4px;
        height: 70px;
        background-color: $orange;

        @include respond-to(extra-large) {
          top: 10px;
        }
        @include respond-to(medium-large) {
          height: 40px;
        }
      }
    }
    @include respond-to(medium-large) {
      width: 50%;
      margin-bottom: 50px;

      &:nth-child(2n + 2) {
        .number:before {
          display: none;
        }
      }
    }
  }
  .number {
    display: block;
    font-size: 100px;
    font-weight: 700;
    line-height: 100px;
    position: relative;

    @include respond-to(medium-large) {
      font-size: 55px;
      line-height: 55px;
    }

    &:before {
      content: "";
      position: absolute;
      top: 20px;
      left: 0;
      width: 4px;
      height: 70px;
      background-color: $orange;

      @include respond-to(extra-large) {
        top: 10px;
      }
      @include respond-to(medium-large) {
        height: 40px;
      }
    }

    @include respond-to(medium-large) {
      &:after {
        content: "";
        position: absolute;
        top: 20px;
        right: 0;
        width: 4px;
        height: 40px;
        background-color: $orange;

        @include respond-to(extra-large) {
          top: 10px;
        }
      }
    }

    @include respond-to(extra-large) {
      font-size: 70px;
      line-height: 70px;
    }
    @include respond-to(large) {
      font-size: 50px;
      line-height: 50px;
    }
  }
  .name {
    display: block;
    font-size: 28px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 4px;
    text-transform: uppercase;
    max-width: 220px;
    margin: 30px auto 0;

    @include respond-to(extra-large) {
      font-size: 24px;
      line-height: 32px;
    }
    @include respond-to(large) {
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 2px;
    }
    @include respond-to(medium-large) {
      font-size: 15px;
      line-height: 20px;
    }
  }
}
